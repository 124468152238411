import { api } from 'dicomweb-client';
import dcmjs from 'dcmjs';
import { utilities } from '@cornerstonejs/core';
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";
import { useMainStore } from '../../../src/store/main';
import WADORSHeaderProvider from './WADORSHeaderProvider';
import getPixelSpacingInformation from './getPixelSpacingInformation';
import { client } from "@/plugins/auth0";

const { DicomMetaDictionary } = dcmjs.data;
const { calibratedPixelSpacingMetadataProvider } = utilities;

const VOLUME = 'volume';

/**
 * Uses dicomweb-client to fetch metadata of a study, cache it in cornerstone,
 * and return a list of imageIds for the frames.
 *
 * Uses the app config to choose which study to fetch, and which
 * dicom-web server to fetch it from.
 *
 * @returns {string[]} An array of imageIds for instances in the study.
 */

export default async function createImageIdsAndCacheMetaData({
    StudyInstanceUID,
    SeriesInstanceUID,
    wadoRsRoot,
    type,
}) {
    const SOP_INSTANCE_UID = '00080018';
    const SERIES_INSTANCE_UID = '0020000E';
    const MODALITY = '00080060';

    const studySearchOptions = {
        studyInstanceUID: StudyInstanceUID,
        seriesInstanceUID: SeriesInstanceUID,
    };

    const accessToken = await (client
            ? client.value.getAccessTokenSilently()
            : undefined);

    const dicomClient = new api.DICOMwebClient({ url: wadoRsRoot, headers: { Authorization: "Bearer " + accessToken} });

    const instances = await dicomClient.retrieveSeriesMetadata(studySearchOptions);
    instances.sort((a, b) => a["00200013"].Value[0] - b["00200013"].Value[0]);

    const imageIds = instances.map((instanceMetaData) => {
        const SeriesInstanceUID = instanceMetaData[SERIES_INSTANCE_UID].Value[0];
        const SOPInstanceUID = instanceMetaData[SOP_INSTANCE_UID].Value[0];
        // streaming has a image limit of 512
        // DO: investigate
        // const prefix = type.toLowerCase() === VOLUME ? 'streaming-wadors:' : 'wadors:';
        const prefix = 'wadors:';

        const imageId =
            prefix +
            wadoRsRoot +
            '/studies/' +
            StudyInstanceUID +
            '/series/' +
            SeriesInstanceUID +
            '/instances/' +
            SOPInstanceUID +
            '/frames/1';

        cornerstoneWADOImageLoader.wadors.metaDataManager.add(
            imageId,
            instanceMetaData
        );

        //removing ismultiframe
        delete instanceMetaData.isMultiframe

        WADORSHeaderProvider.addInstance(imageId, instanceMetaData);

        // Add calibrated pixel spacing
        const m = JSON.parse(JSON.stringify(instanceMetaData));
        const instance = DicomMetaDictionary.naturalizeDataset(m);
        var pixelSpacing = getPixelSpacingInformation(instance);

        if (!pixelSpacing)
            pixelSpacing = [1, 1];

        //hotfix for no pixel spacing data
        try {
            pixelSpacing.map((s) => parseFloat(s))
        } catch {
            pixelSpacing = pixelSpacing.PixelSpacing;
        }

        if (!pixelSpacing)
            pixelSpacing = [1, 1];


        calibratedPixelSpacingMetadataProvider.add(
            imageId,
            pixelSpacing.map((s) => parseFloat(s))
        );

        return imageId;
    });

    var mainStore = useMainStore();

    mainStore.AddImageIdsToChache(StudyInstanceUID, SeriesInstanceUID, imageIds);

    return imageIds;
}