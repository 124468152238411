import { defineStore } from "pinia";
import * as cornerstoneTools from '@cornerstonejs/tools';
import * as cornerstone3D from "@cornerstonejs/core";
import { useMainStore } from '../store/main';
import { useUIStore } from '../store/ui';
import {
    LengthTool,
    RectangleROITool,
    ToolGroupManager,
    Enums as csToolsEnums,
    annotation,
    addTool,
    utilities
} from '@cornerstonejs/tools';
const {
    getOrientationStringLPS,
    invertOrientationStringLPS,
} = utilities.orientation;
import {
    metaData,
    utilities as csUtils,
} from '@cornerstonejs/core';
import {
    camera as cameraHelpers
} from "../../utils/demo/helpers";
import { elementCursor } from "@cornerstonejs/tools/dist/esm/cursors";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";
import { useConfigStore } from "./config";

export const useToolsStore = defineStore("tools", {
    state: () => ({
        test: true,
        VOIpresets: {
            "CTAbdomen": {
                WL: 60,
                WW: 400
            },
            "CTAngio": {
                WL: 300,
                WW: 600
            },
            "CTBone": {
                WL: 300,
                WW: 1500
            },
            "CTBrain": {
                WL: 40,
                WW: 80
            },
            "CTChest": {
                WL: 40,
                WW: 400
            },
            "CTLungs": {
                WL: -400,
                WW: 1500
            }
        },
        toolGroups: {
            stack: {
                id: 'STACK_TOOLS'
            },
            volume: {
                id: 'VOLUME_TOOLS'
            },
            vr: {
                id: 'VR_TOOLS'
            },
        },
        activeMouseTools: {
            0: "WindowLevel", // primary
            1: "Pan", // auxilliary
            2: "Zoom" // secondary
        },
        syncable: [],
        annotationTools: ["Length", "Probe", "RectangleROI", "EllipticalROI", "Bidirectional", "CobbAngle", "ArrowAnnotate"]
    }),
    getters: {},
    actions: {
        SetupToolGroups() {
            const {
                WindowLevelTool,
                PanTool,
                ZoomTool,
                StackScrollTool,
                StackScrollMouseWheelTool,
                TrackballRotateTool,
                LengthTool,
                ProbeTool,
                RectangleROITool,
                EllipticalROITool,
                BidirectionalTool,
                AngleTool,
                ArrowAnnotateTool,
                CrosshairsTool,
                ReferenceLinesTool,
                ReferenceCursors,
                CobbAngleTool,
                ToolGroupManager,
                Enums: csToolsEnums,
                synchronizers,
            } = cornerstoneTools;

            //Stack
            cornerstoneTools.addTool(WindowLevelTool);
            cornerstoneTools.addTool(PanTool);
            cornerstoneTools.addTool(ZoomTool);
            cornerstoneTools.addTool(StackScrollTool);
            cornerstoneTools.addTool(LengthTool);
            cornerstoneTools.addTool(ProbeTool);
            cornerstoneTools.addTool(RectangleROITool);
            cornerstoneTools.addTool(EllipticalROITool);
            cornerstoneTools.addTool(BidirectionalTool);
            cornerstoneTools.addTool(CobbAngleTool);
            cornerstoneTools.addTool(ArrowAnnotateTool);
            cornerstoneTools.addTool(ReferenceLinesTool);

            //MPR
            cornerstoneTools.addTool(CrosshairsTool);
            cornerstoneTools.addTool(ReferenceCursors);

            //VR
            cornerstoneTools.addTool(TrackballRotateTool);

            //For both Stack and Volume
            cornerstoneTools.addTool(StackScrollMouseWheelTool);

            const toolGroupIdStack = this.toolGroups.stack.id;
            const toolGroupStack = ToolGroupManager.createToolGroup(toolGroupIdStack);
            toolGroupStack.addTool(WindowLevelTool.toolName);
            toolGroupStack.addTool(PanTool.toolName);
            toolGroupStack.addTool(ZoomTool.toolName);
            toolGroupStack.addTool(StackScrollTool.toolName);
            toolGroupStack.addTool(StackScrollMouseWheelTool.toolName);
            toolGroupStack.addTool(TrackballRotateTool.toolName);
            toolGroupStack.addTool(LengthTool.toolName);
            toolGroupStack.addTool(ProbeTool.toolName);
            toolGroupStack.addTool(RectangleROITool.toolName);
            toolGroupStack.addTool(EllipticalROITool.toolName);
            toolGroupStack.addTool(BidirectionalTool.toolName);
            toolGroupStack.addTool(CobbAngleTool.toolName);
            toolGroupStack.addTool(ArrowAnnotateTool.toolName);
            toolGroupStack.addTool(ReferenceLinesTool.toolName);

            const toolGroupIdVolume = this.toolGroups.volume.id;
            const toolGroupVolume = ToolGroupManager.createToolGroup(toolGroupIdVolume);
            toolGroupVolume.addTool(StackScrollMouseWheelTool.toolName);
            toolGroupVolume.addTool(cornerstoneTools.CrosshairsTool.toolName, {
                getReferenceLineColor,
                getReferenceLineControllable,
                getReferenceLineDraggableRotatable,
                getReferenceLineSlabThicknessControlsOn,
            });
            toolGroupVolume.addTool(ReferenceCursors.toolName);
            toolGroupVolume.addTool(WindowLevelTool.toolName);

            const toolGroupIdVR = this.toolGroups.vr.id;
            const toolGroupVR = ToolGroupManager.createToolGroup(toolGroupIdVR);
            toolGroupVR.addTool(WindowLevelTool.toolName);

            //this cant be changed by defaults so can stay here
            toolGroupStack.setToolActive(StackScrollMouseWheelTool.toolName);

            //functions needed for crosshair tool
            const renderingEngineId = 'MPRRenderingEngine';

            const viewportId1 = 'CT_AXIAL';
            const viewportId2 = 'CT_SAGITTAL';
            const viewportId3 = 'CT_CORONAL';

            const viewportColors = {
                [viewportId1]: 'rgb(155 71 73)',
                [viewportId2]: 'rgb(193 190 100)',
                [viewportId3]: 'rgb(0 130 50)',
            };

            const viewportReferenceLineControllable = [
                viewportId1,
                viewportId2,
                viewportId3,
            ];

            const viewportReferenceLineDraggableRotatable = [
                viewportId1,
                viewportId2,
                viewportId3,
            ];

            const viewportReferenceLineSlabThicknessControlsOn = [
                viewportId1,
                viewportId2,
                viewportId3,
            ];

            function getReferenceLineColor(viewportId) {
                return viewportColors[viewportId];
            }

            function getReferenceLineControllable(viewportId) {
                const index = viewportReferenceLineControllable.indexOf(viewportId);
                return index !== -1;
            }

            function getReferenceLineDraggableRotatable(viewportId) {
                const index = viewportReferenceLineDraggableRotatable.indexOf(viewportId);
                return index !== -1;
            }

            function getReferenceLineSlabThicknessControlsOn(viewportId) {
                const index =
                    viewportReferenceLineSlabThicknessControlsOn.indexOf(viewportId);
                return index !== -1;
            }

            const { createStackImageSynchronizer, createVOISynchronizer } =
            synchronizers;
            const stackSynchronizerId = 'STACK_SYNCHRONIZER_ID';
            const voiSynchronizerId = 'VOI_SYNCHRONIZER_ID';
            // Create synchronizers
            createStackImageSynchronizer(stackSynchronizerId);
            createVOISynchronizer(voiSynchronizerId);
        },
        Activate3DTool(toolName) {
            const mainStore = useMainStore();
            var isMPRMode = mainStore.mprMode;
            if (!isMPRMode) {
                return;
            }
            const {
                ToolGroupManager,
                Enums: csToolsEnums,
            } = cornerstoneTools;

            var toolGroup = ToolGroupManager.getToolGroup(this.toolGroups.volume.id);
            var toolToUse = toolName.split("-")[0];

            const { MouseBindings } = csToolsEnums;

            if (toolGroup.toolOptions[toolToUse] && toolGroup.toolOptions[toolToUse].mode == "Active") {
                toolGroup.setToolDisabled(toolToUse);
            } else {

                //disable all other tools for MPR
                toolGroup.setToolDisabled("Crosshairs");
                toolGroup.setToolDisabled("WindowLevel");
                toolGroup.setToolDisabled("ReferenceCursors");

                toolGroup.setToolActive(toolToUse, {
                    bindings: [{ mouseButton: MouseBindings.Primary }],
                });
            }

            return;
        },
        SyncStacks(element, renderingEngine, renderingEngineId, stackSynchronizerId, viewport) {
            const mainStore = useMainStore();
            const toolsStore = useToolsStore();

            if (mainStore.multiSelectedViewports.length > 0) {
                return;
            }

            var currentViewportId = element.attributes["data-viewport-uid"].value;

            const { SynchronizerManager } = cornerstoneTools;
            const synchronizer = SynchronizerManager.getSynchronizer(stackSynchronizerId);

            var sources = synchronizer.getSourceViewports();
            var targets = synchronizer.getTargetViewports();

            if (synchronizer) {
                //clear all source and target
                for (let index = sources.length - 1; index >= 0; index--) {
                    const source = sources[index];
                    var temp = {
                        viewportId: source.viewportId,
                        renderingEngineId: renderingEngineId
                    }
                    synchronizer.removeSource(temp);
                }

                for (let index = targets.length - 1; index >= 0; index--) {
                    const target = targets[index];
                    temp = {
                        viewportId: target.viewportId,
                        renderingEngineId: renderingEngineId
                    }
                    synchronizer.removeTarget(temp);
                }
                var self = this;
                var viewportElements = document.querySelectorAll(".viewport-element");
                viewportElements.forEach(viewportElement => {
                    var viewport = renderingEngine.getViewport(viewportElement.parentElement.attributes["data-viewport-uid"].value);
                    var viewportId = viewport.id;
                    if (viewportId == currentViewportId) {
                        var temp = {
                            viewportId: currentViewportId,
                            renderingEngineId: renderingEngineId
                        }
                        synchronizer.addSource(temp);
                    } else {
                        var targetViewport = renderingEngine.getViewports().filter(x => x.id == viewportId)[0];
                        var targetMetadata = cornerstoneWADOImageLoader.wadors.metaDataManager.get(targetViewport.imageIds[targetViewport.getTargetImageIdIndex()]);
                        var targetFORUID = targetMetadata["00200052"]

                        var thisViewport = renderingEngine.getViewports().filter(x => x.id == currentViewportId)[0];
                        var thisMetadata = cornerstoneWADOImageLoader.wadors.metaDataManager.get(thisViewport.imageIds[thisViewport.getTargetImageIdIndex()]);
                        var thisFORUID = thisMetadata["00200052"];

                        if (targetFORUID && thisFORUID && targetFORUID.Value[0] === thisFORUID.Value[0]) {
                            temp = {
                                viewportId: viewportId,
                                renderingEngineId: renderingEngineId
                            }
                            synchronizer.addTarget(temp);
                        }
                    }
                });
            }
        },
        TogglePatientInfo() {
            const UIstore = useUIStore();
            UIstore.showPatientInfo = !UIstore.showPatientInfo;

            var elements = document.querySelectorAll(".patient-info");
            for (let index = 0; index < elements.length; index++) {
                const element = elements[index];

                if (UIstore.showPatientInfo)
                    element.style.display = "block";
                else
                    element.style.display = "none";
            }
        },
        AddOrientationMarkersToViewport(viewport) {
            var currentImageIdIndex = viewport.getTargetImageIdIndex();
            var currentImageId = viewport.imageIds[currentImageIdIndex];
            var imagePlaneModule = metaData.get('imagePlaneModule', currentImageId) || {};

            var rowCosines = imagePlaneModule.rowCosines;
            var columnCosines = imagePlaneModule.columnCosines;
            var rotation = parseInt(viewport.getRotation());
            var flipVertical = viewport.flipVertical;
            var flipHorizontal = viewport.flipHorizontal;

            if (rowCosines && columnCosines) {
                var markers = this.GetOrientationMarkers(rowCosines, columnCosines, rotation, flipVertical, flipHorizontal);

                var leftMarker = markers.left;
                viewport.element.parentElement.parentElement.querySelector(".left-middle .primary-marker").innerHTML = leftMarker.substring(0, 1);
                viewport.element.parentElement.parentElement.querySelector(".left-middle .secondary-marker").innerHTML = leftMarker.substring(1, 2);

                var rightMarker = markers.right;
                viewport.element.parentElement.parentElement.querySelector(".right-middle .primary-marker").innerHTML = rightMarker.substring(0, 1);
                viewport.element.parentElement.parentElement.querySelector(".right-middle .secondary-marker").innerHTML = rightMarker.substring(1, 2);

                var topMarker = markers.top;
                viewport.element.parentElement.parentElement.querySelector(".top-middle .primary-marker").innerHTML = topMarker.substring(0, 1);
                viewport.element.parentElement.parentElement.querySelector(".top-middle .secondary-marker").innerHTML = topMarker.substring(1, 2);

                var bottomMarker = markers.bottom;
                viewport.element.parentElement.parentElement.querySelector(".bottom-middle .primary-marker").innerHTML = bottomMarker.substring(0, 1);
                viewport.element.parentElement.parentElement.querySelector(".bottom-middle .secondary-marker").innerHTML = bottomMarker.substring(1, 2);
            }

        },
        ActivateRefLines() {
            const {
                ReferenceLinesTool,
                ToolGroupManager,
            } = cornerstoneTools;

            const config = useConfigStore();

            const toolGroupIdStack = this.toolGroups.stack.id;
            var toolGroup = ToolGroupManager.getToolGroup(toolGroupIdStack);
            toolGroup.setToolEnabled(ReferenceLinesTool.toolName);

            event.target.closest(".svg-container").classList.add("ref-active");
            config.refLinesEnabled = true;
        },
        DeactivateRefLines() {
            const {
                ReferenceLinesTool,
                ToolGroupManager,
            } = cornerstoneTools;

            const config = useConfigStore();
            const toolGroupIdStack = this.toolGroups.stack.id;
            var toolGroup = ToolGroupManager.getToolGroup(toolGroupIdStack);

            toolGroup.setToolDisabled(ReferenceLinesTool.toolName);

            event.target.closest(".svg-container").classList.remove("ref-active");
            config.refLinesEnabled = false;
        },
        ChangeRefLines() {
            const {
                ReferenceLinesTool,
                ToolGroupManager,
            } = cornerstoneTools;
            const mainStore = useMainStore();

            const toolGroupIdStack = this.toolGroups.stack.id;
            var toolGroup = ToolGroupManager.getToolGroup(toolGroupIdStack);

            if (mainStore.selectedSeriesElement) {
                const viewportId = mainStore.selectedSeriesElement.parentElement.parentElement.attributes["data-viewport-uid"].value;

                toolGroup.setToolConfiguration(
                    ReferenceLinesTool.toolName, {
                        sourceViewportId: viewportId,
                    },
                    true // overwrite
                );

                toolGroup.setToolEnabled(ReferenceLinesTool.toolName);
            }
        },
        EraseOne() {
            const { selection, visibility } = annotation;
            const annotationUIDs = selection.getAnnotationsSelected();
            if (annotationUIDs && annotationUIDs.length) {
                const annotationUID = annotationUIDs[0];

                visibility.setAnnotationVisibility(annotationUID, false);
            }
        },
        EraseAll() {
            const { selection } = annotation;
            const mainStore = useMainStore();
            if (mainStore.selectedSeriesElement) {
                var elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    var renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    var viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    var ct = cornerstoneTools;
                    ct.annotation.state.removeAllAnnotations();
                    viewport.render();
                }
            }

        },
        Reset() {
            const mainStore = useMainStore();
            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;

            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                for (let index = 0; index < mainStore.multiSelectedViewports.length; index++) {
                    const tempViewport = mainStore.multiSelectedViewports[index];
                    tempViewport.resetCamera();
                    tempViewport.resetProperties();
                    tempViewport.render();
                }
            } else if (mainStore.selectedSeriesElement) {
                var elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    var renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    var viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    viewport.resetCamera();
                    viewport.resetProperties();
                    viewport.render();
                }
            }
        },
        ResetWWWC() {
            const mainStore = useMainStore();
            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;

            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                mainStore.multiSelectedViewports.forEach(viewport => {
                    viewport.resetProperties();
                    viewport.render();
                })
            } else if (mainStore.selectedSeriesElement) {
                var elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    var renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    var viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    viewport.resetProperties();
                    viewport.render();
                }
            }
        },
        Invert() {
            const mainStore = useMainStore();
            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;

            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                for (let index = 0; index < mainStore.multiSelectedViewports.length; index++) {
                    const tempViewport = mainStore.multiSelectedViewports[index];
                    const { invert } = tempViewport.getProperties();
                    tempViewport.setProperties({ invert: !invert });
                    tempViewport.render();
                }
            } else if (mainStore.selectedSeriesElement) {
                var elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    var renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    var viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    const { invert } = viewport.getProperties();
                    viewport.setProperties({ invert: !invert });
                    viewport.render();
                }
            }

        },
        RotateLeft() {
            const mainStore = useMainStore();
            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;

            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                for (let index = 0; index < mainStore.multiSelectedViewports.length; index++) {
                    const tempViewport = mainStore.multiSelectedViewports[index];
                    const rotation = tempViewport.getRotation();
                    tempViewport.setRotation(rotation - 90);
                    tempViewport.render();
                }
            } else if (mainStore.selectedSeriesElement) {
                var elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    var renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    var viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    const rotation = viewport.getRotation();
                    viewport.setRotation(rotation - 90);
                    viewport.render();
                }
            }
        },
        RotateRight() {
            const mainStore = useMainStore();
            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;

            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                for (let index = 0; index < mainStore.multiSelectedViewports.length; index++) {
                    const tempViewport = mainStore.multiSelectedViewports[index];
                    const rotation = tempViewport.getRotation();
                    tempViewport.setRotation(rotation + 90);
                    tempViewport.render();
                }
            } else if (mainStore.selectedSeriesElement) {
                var elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    var renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    var viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    const rotation = viewport.getRotation();
                    viewport.setRotation(rotation + 90);
                    viewport.render();
                }
            }

        },
        FlipV() {
            const mainStore = useMainStore();
            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;

            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                for (let index = 0; index < mainStore.multiSelectedViewports.length; index++) {
                    const tempViewport = mainStore.multiSelectedViewports[index];
                    const { flipVertical } = tempViewport.getCamera();
                    tempViewport.setCamera({ flipVertical: !flipVertical });
                    tempViewport.render();
                }
            } else if (mainStore.selectedSeriesElement) {
                var elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    var renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    var viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    const { flipVertical } = viewport.getCamera();
                    viewport.setCamera({ flipVertical: !flipVertical });
                    viewport.render();
                }
            }

        },
        FlipH() {
            const mainStore = useMainStore();
            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;

            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                for (let index = 0; index < mainStore.multiSelectedViewports.length; index++) {
                    const tempViewport = mainStore.multiSelectedViewports[index];
                    const { flipHorizontal } = tempViewport.getCamera();
                    tempViewport.setCamera({ flipHorizontal: !flipHorizontal });
                    tempViewport.render();
                }
            } else if (mainStore.selectedSeriesElement) {
                var elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    var renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    var viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    const { flipHorizontal } = viewport.getCamera();
                    viewport.setCamera({ flipHorizontal: !flipHorizontal });
                    viewport.render();
                }
            }
        },
        FirstIndex() {
            const mainStore = useMainStore();
            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;

            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                console.log('multiselecting');
                mainStore.multiSelectedViewports.forEach(viewport => {
                    viewport.setImageIdIndex(0);
                    viewport.currentImageIdIndex = 0;
                    viewport.targetImageIdIndex = 0;
                })
            } else if (mainStore.selectedSeriesElement) {
                var elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    var renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    var viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    viewport.setImageIdIndex(0);
                    viewport.currentImageIdIndex = 0;
                    viewport.targetImageIdIndex = 0;
                }

            }
        },
        LastIndex() {
            const mainStore = useMainStore();
            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;
            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                mainStore.multiSelectedViewports.forEach(viewport => {
                    viewport.setImageIdIndex(viewport.imageIds.length - 1);
                    viewport.currentImageIdIndex = viewport.imageIds.length - 1;
                    viewport.targetImageIdIndex = viewport.imageIds.length - 1;
                })
            } else if (mainStore.selectedSeriesElement) {
                let elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    let renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    let viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    viewport.setImageIdIndex(viewport.imageIds.length - 1);
                    viewport.currentImageIdIndex = viewport.imageIds.length - 1;
                    viewport.targetImageIdIndex = viewport.imageIds.length - 1;
                }
            }
        },
        ChangeToWindowingPreset(preset) {
            const mainStore = useMainStore();

            const isMultiSelect = mainStore.multiSelectedViewports.length > 0;

            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (isMultiSelect && visibleMultiSelected.length != 0) {
                let WL = this.VOIpresets[preset].WL;
                let WW = this.VOIpresets[preset].WW;
                let utils = csUtils;
                let voirange = utils.windowLevel.toLowHighRange(WW, WL);
                mainStore.multiSelectedViewports.forEach(viewport => {
                    viewport.setProperties({
                        voiRange: voirange,
                    })
                    viewport.element.setAttribute("WL", WL);
                    viewport.element.setAttribute("WW", WW);
                    viewport.render();
                })
            } else if (mainStore.selectedSeriesElement) {
                let elem = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elem.attributes["data-rendering-engine-uid"]) {
                    let renderingEngine = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                    let viewport = renderingEngine.getViewport(elem.attributes["data-viewport-uid"].value);
                    let WL = this.VOIpresets[preset].WL;
                    let WW = this.VOIpresets[preset].WW;
                    let utils = csUtils;
                    let voirange = utils.windowLevel.toLowHighRange(WW, WL);
                    viewport.setProperties({
                        voiRange: voirange,
                    })
                    elem.setAttribute("WL", WL);
                    elem.setAttribute("WW", WW);
                    viewport.render();
                }
            }
        },
        GetOrientationMarkers(
            rowCosines,
            columnCosines,
            rotation,
            flipVertical,
            flipHorizontal
        ) {

            const {
                getOrientationStringLPS,
                invertOrientationStringLPS,
            } = utilities.orientation;

            const rowString = getOrientationStringLPS(rowCosines);
            const columnString = getOrientationStringLPS(columnCosines);
            const oppositeRowString = invertOrientationStringLPS(rowString);
            const oppositeColumnString = invertOrientationStringLPS(columnString);

            const markers = {
                top: oppositeColumnString,
                left: oppositeRowString,
                right: rowString,
                bottom: columnString,
            };

            // If any vertical or horizontal flips are applied, change the orientation strings ahead of
            // the rotation applications
            if (flipVertical) {
                markers.top = invertOrientationStringLPS(markers.top);
                markers.bottom = invertOrientationStringLPS(markers.bottom);
            }

            if (flipHorizontal) {
                markers.left = invertOrientationStringLPS(markers.left);
                markers.right = invertOrientationStringLPS(markers.right);
            }

            // Swap the labels accordingly if the viewport has been rotated
            // This could be done in a more complex way for intermediate rotation values (e.g. 45 degrees)
            if (rotation === 90 || rotation === -270) {
                return {
                    top: markers.left,
                    left: invertOrientationStringLPS(markers.top),
                    right: invertOrientationStringLPS(markers.bottom),
                    bottom: markers.right, // left
                };
            } else if (rotation === -90 || rotation === 270) {
                return {
                    top: invertOrientationStringLPS(markers.left),
                    left: markers.top,
                    bottom: markers.left,
                    right: markers.bottom,
                };
            } else if (rotation === 180 || rotation === -180) {
                return {
                    top: invertOrientationStringLPS(markers.top),
                    left: invertOrientationStringLPS(markers.left),
                    bottom: invertOrientationStringLPS(markers.bottom),
                    right: invertOrientationStringLPS(markers.right),
                };
            }

            return markers;
        },
        EnterMPRMode() {
            const mainStore = useMainStore();

            var can3D = true;

            if (!mainStore.selectedSeriesElement) {
                alert('Please select a series before entering MPR mode');
                return;
            }

            var elem = mainStore.selectedSeriesElement.parentElement.parentElement;
            let renderingEngineOriginal;
            let viewport;
            if (elem.attributes["data-rendering-engine-uid"]) {
                renderingEngineOriginal = cornerstone3D.getRenderingEngine(elem.attributes["data-rendering-engine-uid"].value);
                viewport = renderingEngineOriginal.getViewport(elem.attributes["data-viewport-uid"].value);
            } else {
                alert('Please select a series before entering MPR mode');
                return;
            }

            var metadata = cornerstoneWADOImageLoader.wadors.metaDataManager.get(viewport.imageIds[viewport.getTargetImageIdIndex()]);
            var studyUID = metadata["0020000D"].Value[0];
            var seriesUID = metadata["0020000E"].Value[0];
            mainStore.mprStudyUID = studyUID;
            mainStore.mprSeriesUID = seriesUID;

            if (can3D) {
                mainStore.mprMode = true;
                document.getElementsByClassName("MPR-3D")[0].parentElement.style.border = "1px dashed #76ebfdec";
            } else {
                alert("Unable to create volume 3D volume for this series.")
            }
            mainStore.mprMode = true;
        },
        ExitMPRMode() {
            const mainStore = useMainStore();

            mainStore.mprMode = false;
            document.getElementsByClassName("MPR-3D")[0].parentElement.style.border = "1px dashed transparent";
            mainStore.mprStudyUID = "";
            mainStore.mprSeriesUID = "";
        },
        EnterVRMode() {
            const mainStore = useMainStore();

            mainStore.vrMode = true;
        },
        ExitVRMode() {
            const mainStore = useMainStore();

            mainStore.vrMode = false;
        },
        PlaySeries() {
            const mainStore = useMainStore();
            const UIstore = useUIStore();
            const {
                utilities: csToolsUtilities,
            } = cornerstoneTools;
            let framesPerSecond = mainStore.seriesFPS;
            if (event.target.closest(".cine-controls")) { //instance play
                var elementI;

                elementI = event.target.closest(".canvas-container").children[0].children[0];


                csToolsUtilities.cine.playClip(elementI, { framesPerSecond });
            } else if (!mainStore.selectedSeriesElement) {
                alert("Please select a series to play!")
            } else if (mainStore.selectedSeriesElement) { //series play
                var elementS = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elementS.attributes["data-rendering-engine-uid"]) {
                    csToolsUtilities.cine.playClip(elementS, { framesPerSecond });
                } else {
                    alert("Please select a series to play!")
                }
            }
        },
        StopSeries() {
            const mainStore = useMainStore();
            const UIstore = useUIStore();
            const {
                utilities: csToolsUtilities,
            } = cornerstoneTools;
            if (event.target.closest(".cine-controls")) { //instance stop
                var elementI;

                elementI = event.target.closest(".canvas-container").children[0].children[0];


                csToolsUtilities.cine.stopClip(elementI);
            } else if (mainStore.selectedSeriesElement) { //series stop
                var elementS = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (elementS.attributes["data-rendering-engine-uid"]) {
                    csToolsUtilities.cine.stopClip(elementS);
                } else {
                    alert("Please select a series to stop!")
                }
            }
        },
        ChangeFPS() {
            const mainStore = useMainStore();
            const {
                utilities: csToolsUtilities,
            } = cornerstoneTools;

            if (mainStore.selectedSeriesElement) {
                var element = mainStore.selectedSeriesElement.parentElement.parentElement;
                if (element.attributes["data-rendering-engine-uid"]) {
                    csToolsUtilities.cine.stopClip(element);
                    var framesPerSecond = mainStore.seriesFPS;
                    csToolsUtilities.cine.playClip(element, { framesPerSecond });
                }

            }
        },
        getToolGroupMouseBindingFromEventWhichValue(mouseButton) {
            const { MouseBindings } = csToolsEnums;

            let csMouseButton;
            switch (mouseButton) {
                case 0:
                    csMouseButton = MouseBindings.Primary;
                    break;
                case 2:
                    csMouseButton = MouseBindings.Secondary;
                    break;
                case 1:
                    csMouseButton = MouseBindings.Auxiliary;
                    break;
                default:
                    csMouseButton = mouseButton;
                    break;
            }

            return csMouseButton;
        },
        /**
         * Activate the given tool on the given mouse button. If the tool exists on another
         * mouse button then disable that mouse input
         * 
         * @param {*} toolName 
         * @param {*} mouseButton 
         */
        ActivateTool(toolName, mouseButton) {
            //Check if it is 3D tool
            if (toolName.includes("3D")) {
                this.Activate3DTool(toolName);
                return;
            }
            const {
                ToolGroupManager,
            } = cornerstoneTools;

            var toolGroup = ToolGroupManager.getToolGroup(this.toolGroups.stack.id);

            if (Object.values(this.activeMouseTools).includes(toolName)) {
                Object.keys(this.activeMouseTools).forEach(key => {
                    if (this.activeMouseTools[key] == toolName) {
                        this.activeMouseTools[parseInt(key)] = "none";

                    }
                });
            }

            this.activeMouseTools[mouseButton] = toolName;

            /**
             * Set all tools to disabled or passive which prevents mouse input 
             * 
             * Passive allows annoatations to stay on screen
             */
            for (let _toolName in toolGroup.toolOptions) {
                if (_toolName === 'StackScrollMouseWheel') continue;

                let tool = toolGroup.toolOptions[_toolName];
                for (let i = 0; i < tool.bindings.length; i++) {
                    if (!this.annotationTools.includes(_toolName)) {
                        toolGroup.setToolDisabled(_toolName);
                    } else {
                        // disable to destroy mouse bindings
                        toolGroup.setToolDisabled(_toolName);
                        // set passive to keep annotation on screen
                        toolGroup.setToolPassive(_toolName);
                    }
                }
            }

            // enable all tools in active mouse tools object
            Object.keys(this.activeMouseTools).forEach(key => {
                const mouseMapping = this.getToolGroupMouseBindingFromEventWhichValue(parseInt(key))
                    //set new active tool for mouse button
                if (this.activeMouseTools[key] !== "none") {
                    toolGroup.setToolActive(this.activeMouseTools[key], {
                        bindings: [{
                            mouseButton: mouseMapping,
                        }, ],
                    });
                }
            });
        }
    }
});