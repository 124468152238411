import { createApp } from "vue";
import { createPinia } from "pinia";
import "material-icons/iconfont/material-icons.css";
import App from "./App.vue";
import router from "./router";
import * as cornerstone3D from "@cornerstonejs/core";
import * as cornerstone3DTools from "@cornerstonejs/tools";
import dicomParser from "dicom-parser";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";
import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css";
import ContextMenu from "@imengyu/vue3-context-menu";

// Custom
import { createAuth0 } from "@auth0/auth0-vue";
import { exposeAuth0 } from "./plugins/auth0";
import { useConfigStore } from "./store/config";
import { configureAuth0Interceptor } from '@/plugins/auth0-interceptor'

//1. Initialize Cornerstone and Cornerstone Tools
cornerstone3D
  .init()
  .then(cornerstone3DTools.init())
  .then(() => {
    //2. Configure cornerstoneWADOImageLoader
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone3D;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

    /**
     * ADDED to try and combat 512 image limit on volume image loading
     * but did not work
     *
     */
    // cornerstoneWADOImageLoader.external.cornerstone = cornerstone3D;

    // cornerstone3D.imageLoader.registerImageLoader(
    //   "http",
    //   cornerstoneWADOImageLoader.loadImage
    // );

    cornerstoneWADOImageLoader.configure({
      useWebWorkers: true,
      decodeConfig: {
        convertFloatPixelDataToInt: false,
      },
      beforeSend: function(xhr) {
            // Add custom headers here (e.g. auth tokens)
            // Can't do a call getAccessTokenSilently() here because it is async
            //todo: should add a check to see if the token is expired and refresh it if it is
            const configStore = useConfigStore();
            xhr.setRequestHeader("Authorization", "Bearer " + configStore.AccessToken);
        },
        
    });

    var config = {
      maxWebWorkers: navigator.hardwareConcurrency / 2 || 1,
      startWebWorkersOnDemand: true,
      taskConfiguration: {
        decodeTask: {
          initializeCodecsOnStartup: true,
          usePDFJS: false,
          strict: false,
        },
      },
    };
    cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
    //reference lines do not work correctly with CPU rendering
    //cornerstone3D.setUseCPURendering(true)

    //3. Create the Vue 3 app and add Pinia + Vue Router
    var app = createApp(App);
    app.use(createPinia());
    const configStore = useConfigStore();

    app
      .use(router)
      .use(ContextMenu)
      .use(
        createAuth0({
          domain: configStore.auth0.domain,
          clientId: configStore.auth0.clientId,
          authorizationParams: {
            redirect_uri: window.location.origin,
            audience: configStore.auth0.authorizationParams?.audience,
            scope: configStore.auth0.authorizationParams?.scope,
          },
          cacheLocation: "localstorage",
        })
      )
      .use(exposeAuth0())
      .use(configureAuth0Interceptor())
      // .use(xhrInterceptor)
      .mount("#app");
  });
