import { defineStore } from "pinia";

export const useConfigStore = defineStore("config", {
  state: () => ( 
    {
    wadoRoot: process.env.VUE_APP_WADO_ROOT_URL,
    qidoRoot: process.env.VUE_APP_QIDO_ROOT_URL,
    dblclickThumbnail: true,
    thumbnailsDefault: {
      enabled: true,
      sizeOnCtrl: false, // Only show size dropdown when holding ctrl
      sizeH: "large", // 'small' | 'medium' | 'large'
      sizeV: "large", // 'small' | 'medium' | 'large'
      reactive: false,
      VerticalWhenPortrait: false,
      verticalWhenLandscape: true,
      allowChangeOrientation: false,
      allowChangeLocation: true,
    },
    canvasPatientDetails: {
      age: true,
      sex: false,
      seriesNumber: true,
      name: true,
      desc: true,
    },
    showRelatedStudies: true,
    refLinesEnabled: true,
    defaultsRoot: process.env.VUE_APP_DEFAULT_ROOT,
    auth0: {
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE,
        scope: process.env.VUE_APP_AUTH0_SCOPE,
      },
      cacheLocation: "localstorage",
    },
  }),
  getters: {},
  actions: {},
  AccessToken: ""
});
