import { defineStore } from "pinia";
import { useUIStore } from "./ui";
import { useMainStore } from "./main";
import { useToolsStore } from "./tools";
import { create, all } from 'mathjs';
import { RenderingEngine } from "@cornerstonejs/core";
import * as cornerstoneTools from '@cornerstonejs/tools';
import * as cornerstone3D from "@cornerstonejs/core";

export const useUtilsStore = defineStore("utils", {
    state: () => ({
        DICOMTags: {
            MODALITY: "00080060",
        }
    }),
    getters: {

    },
    actions: {
        /**
         * Return a list consisting of only the unique elements from the given list
         * 
         * Removes empty lists
         * 
         * @param {*} list 
         * @returns 
         */
        getListOfUniqueElements(list) {
            const filteredList = list.filter(item => !(Array.isArray(item) && item.length === 0));
            const uniqueSet = new Set(filteredList.map(JSON.stringify));
            const listOfUniqueElements = Array.from(uniqueSet, JSON.parse);
            return listOfUniqueElements;
        },
        /**
         * Retrieve rendering engine with corresponding id
         * 
         * If the rendering engine cannot be found create a new one
         * 
         * @param {*} renderingEngineId 
         * 
         * @returns rendering engine
         */
        getRenderingEngine(renderingEngineId) {
            let renderingEngine;
            if (cornerstone3D.getRenderingEngine(renderingEngineId)) {
                renderingEngine = cornerstone3D.getRenderingEngine(renderingEngineId);
            } else {
                renderingEngine = new RenderingEngine(renderingEngineId);
            }

            return renderingEngine;
        },
        getViewportFromCanvasID(canvasId) {
            const renderingEngine = this.getRenderingEngine('MCRenderingEngine');
            const viewport = renderingEngine.getViewport(`STACK_${canvasId}`);

            return viewport
        },
        /**
         * Calculate image plane based on image orientation metadata 
         * 
         * @param {*} imageOrientation [Xx,Xy,Xz,Yx,Yy,Yz]
         * @returns Axial, Sagittal, Coronal or Oblique
         */
        getImagePlaneFromVectorCosines(imageOrientationMetadata) {
            // instantiate math object
            const config = {};
            const math = create(all, config);

            // Get row and column vectors
            const image_x = imageOrientationMetadata.slice(0, 3);
            const image_y = imageOrientationMetadata.slice(3);

            // Calculate the normal vector to the image plane
            // cross product of row and column vectors
            const normalVector = [
                image_x[1] * image_y[2] - image_x[2] * image_y[1],
                image_x[2] * image_y[0] - image_x[0] * image_y[2],
                image_x[0] * image_y[1] - image_x[1] * image_y[0]
            ];

            // Determine the orientation based on the normal vector
            if ((normalVector[0] === 1 || normalVector[0] === -1) && normalVector[1] === 0 && normalVector[2] === 0) {
                return 'Sagittal';
            } else if (normalVector[0] === 0 && (normalVector[1] === 1 || normalVector[1] === -1) && normalVector[2] === 0) {
                return 'Coronal';
            } else if (normalVector[0] === 0 && normalVector[1] === 0 && (normalVector[2] === 1 || normalVector[2] === -1)) {
                return 'Transverse (Axial)';
            }

            // possibly dealing with oblique

            // Calculate cross product
            const image_z = math.cross(image_x, image_y);

            // calculate absolute value or cross product vector
            const abs_image_z = image_z.map(Math.abs);

            // find the index of the maximum value of absolute corss product vectors
            const max_abs_image_z = Math.max(...abs_image_z);
            const main_index = abs_image_z.indexOf(max_abs_image_z);

            /**
             * Calculate euclidean norm of each vector ->
             * caculate dot product of above with each vector ->
             * calculate inverse cosine of above
             * 
             * returns result in radians
             */
            let angle_xz = math.acos(math.dot(image_x, [1, 0, 0]) / math.norm(image_x));
            let angle_yz = math.acos(math.dot(image_y, [0, 1, 0]) / math.norm(image_y));
            let angle_xy = math.acos(math.dot(image_z, [0, 0, 1]) / math.norm(image_z));

            /**
             * Convert from radians to pi
             */
            angle_xz *= (180 / Math.PI);
            angle_yz *= (180 / Math.PI);
            angle_xy *= (180 / Math.PI);
            // Ten degree viewing angle tolerance 
            const tolerance = 15
                // Check if angles are within tolerance of 90 degrees or 0 degrees
            if (
                (Math.abs(90 - angle_xz) <= tolerance || Math.abs(angle_xz) <= tolerance) &&
                (Math.abs(90 - angle_yz) <= tolerance || Math.abs(angle_yz) <= tolerance) &&
                (Math.abs(90 - angle_xy) <= tolerance || Math.abs(angle_xy) <= tolerance)
            ) {
                // Inside tolerance is standard viewing angle
                if (Math.round(Math.abs(normalVector[0])) == 1 && Math.round(Math.abs(normalVector[1])) == 0 && Math.round(Math.abs(normalVector[2])) == 0) {
                    return 'Sagittal';
                } else if (Math.round(Math.abs(normalVector[1])) == 1 && Math.round(Math.abs(normalVector[0])) == 0 && Math.round(Math.abs(normalVector[2])) == 0) {
                    return 'Coronal';
                } else if (Math.round(Math.abs(normalVector[2])) == 1 && Math.round(Math.abs(normalVector[1])) == 0 && Math.round(Math.abs(normalVector[0])) == 0) {
                    return 'Transverse (Axial)';
                } else {
                    "Oblique";
                }
            } else {
                // Outside of tolerance is considered oblique
                return "Oblique";
            }
        },
        /**
         * Get formatted dateString from dicom date
         * @param {*} date yyyymmdd
         * @returns yyyy-mm-dd
         */
        getDateStringFromDICOMDate(date) {
            // Create a regular expression for the "yyyy-mm-dd" format
            const regex = /^\d{4}-\d{2}-\d{2}$/;

            // Test the inputString against the regular expression
            const alreadyFormatted = regex.test(date);

            if (alreadyFormatted) {
                return date;
            }

            var year = date.substring(0, 4);
            var month = date.substring(4, 6);
            var day = date.substring(6, 8);

            return `${year}-${month}-${day}`;
        },
        /**
         * Get DICOM abbreviated version of viewposition string 
         * 
         * AP - Anterior/Posterior
         * 
         * PA - Posterior/Anterior
         * 
         * LL - Left Lateral
         * 
         * RL - Right Lateral
         * 
         * RLDL - Right Lateral Decubitus
         * 
         * LLDL - Left Lateral Decubitus
         * 
         * RLO - Right Lateral Oblique
         * 
         * LLO - Left Lateral Oblique
         * 
         * @param {*} viewPosition Full view position string 
         * @returns Abbreviated view position string
         */
        getAbbreviatedViewPosition(viewPosition) {
            let abbreviated = "";

            switch (viewPosition) {
                case "Anterior/Posterior":
                    abbreviated = "AP";
                    break;
                case "Posterior/Anterior":
                    abbreviated = "PA";
                    break;
                case "Left Lateral":
                    abbreviated = "LL";
                    break;
                case "Right Lateral":
                    abbreviated = "RL";
                    break;
                case "Right Lateral Decubitus":
                    abbreviated = "RLDL";
                    break;
                case "Left Lateral Decubitus":
                    abbreviated = "LLDL";
                    break;
                case "Right Lateral Oblique":
                    abbreviated = "RLO";
                    break;
                case "Left Lateral Oblique":
                    abbreviated = "LLO";
                    break;
                default:
                    break;
            }

            return abbreviated;
        },
        /**
         * Compare two dates and return true if first date is "older" than or equal to second date
         * 
         * @param {*} DateOne yyyy-mm-dd
         * @param {*} DateTwo yyyy-mm-dd
         * @returns boolean
         */
        compareDates(DateOne, DateTwo) {
            const date1 = new Date(DateOne);
            const date2 = new Date(DateTwo);

            if (date1 <= date2) {
                return true;
            } else {
                return false;
            }
        },
        /**
         * Get the name of series object attribute from provided string 
         * 
         * Series Description -> seriesDescription
         * 
         * Modality -> modality
         * 
         * @param {*} string 
         * @returns series object attribute name
         */
        getSeriesAttributeFromString(string) {
            let returnValue = "";

            switch (string) {
                case "Series Description":
                    returnValue = "seriesDescription";
                    break;
                case "Study Description":
                    returnValue = "studyDescription";
                    break;
                case "Modality":
                    returnValue = "modality";
                    break;
                case "Series Age":
                    returnValue = "seriesDate";
                    break;
                case "Study Age":
                    returnValue = "studyDate";
                    break;
                case "View Position":
                    returnValue = "viewPosition";
                    break;
                case "Laterality":
                    returnValue = "laterality";
                    break;
                case "Image Type":
                    returnValue = "imageType";
                    break;
                case "Image Plane":
                    returnValue = "imagePlane";
                    break;
                default:
                    returnValue = "";
                    break;
            }

            return returnValue;
        },
        /**
         * Return dicom tag based on provided keyword
         * 
         * @param {*} Keyword case insensitive keyword
         * @returns tag in format [xxxxxxxx]
         */
        getDICOMTagFromKeyword(keyword) {
            return this.DICOMTags[keyword.toUpperCase()];
        },
        /**
         * Calculatye canvas index relative to canvas position in grid and chosen layout
         * 
         * @param {*} position 'row,col'
         * @param {*} layout 'rowxcol'
         * @returns Canvas index
         */
        calculateCanvasIndex(position, layout) {
            const [row, col] = position.split(',').map(Number);
            const [rowCount, colCount] = layout.split('x').map(Number);

            if (row < 1 || row > rowCount || col < 1 || col > colCount) {
                // Check if the provided position is out of bounds
                return -1; // Return a sentinel value to indicate an invalid position
            }

            // Calculate the node number based on the position and layout
            const nodeNumber = (row - 1) * colCount + col;
            return nodeNumber - 1;
        },
        /**
         * Returns canvas position in format rowxcol based on it's canvas id
         * and the current layout
         * 
         * @param {*} canvasId 
         * @returns rowxcol
         */
        calculatePositionFromCanvasId(canvasId) {
            const main = useMainStore();

            const numColumns = main.currentLayout.split('x')[1];

            const row = Math.floor((canvasId - 1) / numColumns);
            const column = (canvasId - 1) % numColumns;
            return `${row + 1}x${column + 1}`;
        },
        generateUniqueId(length = 20) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
        },
        /**
         * Return series thumbnail class 
         * 
         * @param {*} thumbnailSize small/medium/large
         * @returns ".series-thumbnail .small OR .medium OR .large"
         */
        getThumbClass(thumbnailSize) {
            const classList = {
                "series-thumbnail": true,
            };

            switch (thumbnailSize) {
                case "small":
                    classList["small"] = true;
                    break;
                case "medium":
                    classList["medium"] = true;
                    break;
                case "large":
                    classList["large"] = true;
                    break;
                default:
                    classList["medium"] = true;
                    break;
            }

            return classList;
        },
        /**
         * Return thumbnail image class
         * 
         * @param {*} thumbnailSize (small/medium.large)-img 
         * @returns ".series-thumbnail-image .small-img OR .medium-img OR. large-img"
         */
        getThumbImgClass(thumbnailSize) {
            const classList = {
                "series-thumbnail-image": true,
            };

            switch (thumbnailSize) {
                case "small":
                    classList["small-img"] = true;
                    break;
                case "medium":
                    classList["medium-img"] = true;
                    break;
                case "large":
                    classList["large-img"] = true;
                    break;
                default:
                    classList["medium-img"] = true;
                    break;
            }

            return classList;
        },
        /**
         * Handle swapping thumbnail bar location 
         * 
         * If in vertical will swap between top/bottom
         * 
         * If in horizontal will swap between left/right
         */
        handleMoveThumbnail() {
            const uiStore = useUIStore();

            if (uiStore.thumbnailVertical) {
                uiStore.thumbnailLeft = !uiStore.thumbnailLeft;
            }

            if (!uiStore.thumbnailVertical) {
                uiStore.thumbnailBottom = !uiStore.thumbnailBottom;
            }
        },
        /**
         * Calculate age from provided date of birth
         * 
         * @param {*} dob yyyymmdd
         * @returns age integer
         */
        GetAgeFromDOB(dob) {
            var today = new Date();
            var year = dob.substring(0, 4);
            var month = dob.substring(4, 6);
            var day = dob.substring(6, 8);

            var dateString = `${year}-${month}-${day}`;
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        /**
         * Retrieve age from thumbnail data
         * 
         * @param {*} thumbnail Thumbnail data
         * @returns age
         */
        PatientAge(thumbnail) {
            var dob = thumbnail.DOB;
            if (dob.length < 1) return "-";
            else {
                var age = this.GetAgeFromDOB(thumbnail.DOB);
                return age;
            }
        },
        /**
         * Retrieve date of birth from thumbnail data
         * 
         * @param {*} thumbnail Thumbnail data
         * @returns Date of birth mm-dd-yyyy
         */
        PatientDOB(thumbnail) {
            var dateString = thumbnail.DOB;
            if (dateString == "") {
                return "-";
            } else {
                var year = dateString.substring(0, 4);
                var month = dateString.substring(4, 6);
                var day = dateString.substring(6, 8);

                return `${month}-${day}-${year}`;
            }
        },
        /**
         * Retrieve study date from thumbnail data
         * 
         * @param {*} thumbnail Thumbnail data
         * @returns study date mm-dd-yyyy
         */
        StudyDate(thumbnail) {
            var dateString = thumbnail.studyDate;
            var year = dateString.substring(0, 4);
            var month = dateString.substring(4, 6);
            var day = dateString.substring(6, 8);

            return `${month}-${day}-${year}`;
        },
        /**
         * Retrieve study time from thumbnail data
         * 
         * @param {*} thumbnail Thumbnail data
         * @returns study time hh-mm-ss
         */
        StudyTime(thumbnail) {
            const mainStore = useMainStore();

            return `${mainStore.formatTime(thumbnail.studyTime)}`;
        },
        /**
         * Retrieve number of images in series from thumbnail data
         * 
         * @param {*} thumbnail Thumbnail data
         * @returns total number of images in series
         */
        NumberOfImages(thumbnail) {
            var total = 0;
            var allseries = thumbnail.series;
            for (let index = 0; index < allseries.length; index++) {
                const series = allseries[index];
                total += series.seriesRelatedInstances;
            }
            return total;
        },
        /**
         * Handle swapping the orientation of the thumbnail bar
         * 
         * DOES NOT WORK | NOT SUPPORTED
         * 
         * @param {*} location up/down/left/right
         */
        handleThumbnailOrientation(location) {
            const uiStore = useUIStore();

            switch (location) {
                case "up":
                    uiStore.thumbnailBottom = false;
                    uiStore.thumbnailVertical = !uiStore.thumbnailVertical;

                    break;
                case "down":
                    uiStore.thumbnailBottom = true;
                    uiStore.thumbnailVertical = !uiStore.thumbnailVertical;

                    break;
                case "right":
                    uiStore.thumbnailLeft = false;
                    uiStore.thumbnailVertical = !uiStore.thumbnailVertical;

                    break;
                case "left":
                    uiStore.thumbnailLeft = true;
                    uiStore.thumbnailVertical = !uiStore.thumbnailVertical;

                    break;
                default:
                    return;
            }

        },
        // Function to replace backslashes with "\\\\" for MySQL compliance
        escapeBackslashesForMySQL(inputString) {
            return inputString.replace(/\\/g, '\\\\');
        },
        /**
         * Remove viewport from tool group and disable element
         * 
         * @param {*} viewportId 
         * @returns 
         */
        destroyViewport(viewportId) {
            const toolStore = useToolsStore();
            const { ToolGroupManager } = cornerstoneTools;

            const RENDERING_ENGINE_ID = 'MCRenderingEngine';

            const renderingEngine = this.getRenderingEngine(RENDERING_ENGINE_ID);

            const viewport = renderingEngine.getViewport(viewportId);

            if (!viewport) return

            const toolGroupId = toolStore.toolGroups.stack.id;
            const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);

            // destroy viewport and remove from toolgroup
            toolGroup.removeViewports(RENDERING_ENGINE_ID, viewportId);
            renderingEngine.disableElement(viewportId);
        },
        /**
         * Returns true if str2 appears in str1 with all characters appearing in the same
         * order
         * 
         * EXAMPLE: str1 = T2 DEQ FSE SAG, str2 = T2 FS SAG returns true
         * 
         * @param {*} str1 Containing string
         * @param {*} str2 String to compare against containing string
         * @returns boolean
         */
        containsInOrder(str1, str2) {
            let index = 0;
            for (let char of str1) {
                if (char === str2[index]) {
                    index++;
                }
                if (index === str2.length) {
                    return true;
                }
            }
            return false;
        }
    }
});