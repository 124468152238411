import { createRouter, createWebHistory } from 'vue-router'
import { createAuthGuard } from "@auth0/auth0-vue";
const authGuard = createAuthGuard();

const routes = [
    {
        path: '/:studyUID',
        name: 'Home',
        beforeEnter: authGuard,
        component: () =>
            import('../views/Viewer.vue')
    },
    {
        path: '/viewer/:studyUID',
        name: 'Viewer',
        beforeEnter: authGuard,
        component: () =>
            import('../views/Viewer.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router