import axios from "axios";
import { unref } from "vue";
import { client } from "@/plugins/auth0";
import { useConfigStore } from "../store/config";

export function configureAuth0Interceptor() {
  return {
    install(app) {
      // Request interceptor
      axios.interceptors.request.use(
        async (config) => {
          const accessToken = await (client
            ? client.value.getAccessTokenSilently()
            : undefined);
          if (accessToken) {
            const configStore = useConfigStore();
            if (configStore.AccessToken != accessToken) {
              configStore.AccessToken = accessToken;
            }

            if (!config.headers) {
              config.headers = {
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              };
            } else {
              config.headers.Authorization = `Bearer ${accessToken}`;
            }
          }
          return config;
        },
        (error) => {
          // Do something with request error
          return Promise.reject(error);
        }
      );
    },
  };
}
