import { defineStore } from "pinia";
import { useConfigStore } from './config';
import { useUIStore } from './ui';
import axios from "axios";
import {
    createImageIdsAndCacheMetaData,
} from "../../utils/demo/helpers";
import { RenderingEngine, Enums, utilities as csUtils, EVENTS } from "@cornerstonejs/core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";
import * as cornerstoneTools from '@cornerstonejs/tools';
import * as cornerstone3D from "@cornerstonejs/core";
import {
    LengthTool,
    RectangleROITool,
    ToolGroupManager,
    Enums as csToolsEnums,
    annotation,
    addTool,
} from '@cornerstonejs/tools';
import { useToolsStore } from "./tools";
import { config } from "@cornerstonejs/tools/dist/esm/stateManagement/annotation";
import { useUtilsStore } from "./utils";

export const useMainStore = defineStore("main", {
    state: () => ({
        studiesLoaded: [],
        imagesLoaded: false,
        preDataLoaded: false,
        selectedCanvas: -1,
        totalCanvases: -1,
        currentStudy: "",
        currentSeries: "",
        mprMode: false,
        mprStudyUID: "",
        mprSeriesUID: "",
        vrMode: false,
        selectedSeries: 1,
        selectedSeriesElement: null,
        selectedStudy: 1,
        studyBefore3D: null,
        cachedSeriesMetadata: [],
        currentLayout: "1x1",
        prevLayout: "",
        fromSeries: 1,
        toSeries: 1,
        focusSeries: false,
        focusedStudyUID: "",
        focusedSeriesUID: "",
        firstCanvasLoad: true,
        canvases: new Array(32),
        layoutBefore3D: "",
        relatedStudies: [],
        seriesFPS: 0,
        cinedata: [],
        currentEchoView: "",
        currentEchoStage: "",
        echoSeries: [],
        autoStartEcho: true,
        byView: true,
        isEchoLayout: false,
        checkForStressEcho: false,
        selectedEchoIndex: 0,
        syncStacks: true,
        cacheImages: true,
        allThumbnailsLoaded: false,
        cachedSeriesUIDs: [],
        mainSeriesUIDs: [],
        priorSeriesUIDs: [],
        imageIdsCache: [],
        VRLastStudyUID: '',
        VRLastSeriesUID: '',
        VRImageIdsCache: [],
        VRVolumeCache: null,
        multiSelectedViewports: [],
        multiSelectedViewportCache: [],
        defaults: [],
        currentPreset: '',
        thumbnailDefaults: [],
    }),
    actions: {
        firstCanvasLoadDone() {
            this.firstCanvasLoad = false;
        },
        AddImageIdsToChache(studyUID, seriesUID, imageIds) {
            var temp = {
                studyUID: studyUID,
                seriesUID: seriesUID,
                imageIds: imageIds
            }

            var record = this.imageIdsCache.filter(x => x.studyUID == studyUID && x.seriesUID == seriesUID);
            if (!record.length > 0)
                this.imageIdsCache.push(temp);
        },
        formatTime(time) {
            var timePart1 = time.split(".")[0];

            var h = timePart1.substring(0, 2);
            var m = timePart1.substring(2, 4);
            var s = timePart1.substring(4, 6);

            var result = `${h}:${m}:${s}`;
            return result;
        },
        CinePlay(viewport) {
            var toolsStore = useToolsStore();
            var imageIds = viewport.imageIds;
            var currentImageId = viewport.getCurrentImageId();
            var seriesUID = currentImageId.substring(currentImageId.indexOf("series") + 7, currentImageId.indexOf("/instances"));
            if (this.cinedata.filter(x => x.seriesUID == seriesUID).length > 0) {
                this.CineSoftPlay();
            } else {

                var cwi = cornerstoneWADOImageLoader;
                var metadata = cwi.wadors.metaDataManager.get(imageIds[viewport.getTargetImageIdIndex()]);
                var numberOfFrames = metadata["00280008"] ? metadata["00280008"].Value[0] : 1;
                //make multiframe stack
                var multiFrameStack = [];
                for (let index = 0; index < numberOfFrames; index++) {
                    var frameId = `${currentImageId.split("frames/")[0]}frames/${index + 1}`
                    multiFrameStack.push(frameId);
                }
                viewport.setStack(multiFrameStack, 0);
                toolsStore.PlaySeries(); //act as if it is a series

                var temp = {
                    seriesUID: seriesUID,
                    playing: true
                }
                this.cinedata.push(temp);
            }
        },
        CineSoftPlay(viewport) {
            var toolsStore = useToolsStore();
            toolsStore.PlaySeries();
        },
        CineSoftStop(viewport) {
            var toolsStore = useToolsStore();
            toolsStore.StopSeries();
        },
        CineStop(viewport) {
            var currentImageId = viewport.getCurrentImageId();
            var cwi = cornerstoneWADOImageLoader;
            var metadata = cwi.wadors.metaDataManager.get(viewport.imageIds[viewport.getTargetImageIdIndex()]);
            var studyUID = metadata["0020000D"].Value[0];
            var seriesUID = metadata["0020000E"].Value[0];

            // //revert to normal
            var toolsStore = useToolsStore();
            // var study = this.studiesLoaded.filter(x => x.studyUID == studyUID)[0];
            // var instanceUID = currentImageId.substring(currentImageId.indexOf("instances") + 10, currentImageId.indexOf("/frames"));
            // var series = study.series.filter(x => x.seriesUID == seriesUID)[0];
            // var initialStack = series.imageIds;

            // var indexOfInstance = initialStack.indexOf(initialStack.filter(x => x.includes(instanceUID))[0]);
            // viewport.setStack(initialStack, indexOfInstance);
            toolsStore.StopSeries();

            //remove
            this.cinedata.splice(this.cinedata.indexOf(this.cinedata.filter(x => x.seriesUID == seriesUID)[0]), 1);

        },
        SetLoaderTopLeft(top, left) {
            //loader position update
            var loaders = document.getElementsByClassName("loader");

            for (let index = 0; index < loaders.length; index++) {
                const loader = loaders[index];
                loader.style.top = top;
                loader.style.left = left;
            }

        },
        ConvertStudyDate(dateString) {
            var year = dateString.substring(0, 4);
            var month = dateString.substring(4, 6);
            var day = dateString.substring(6, 8);

            return `${month}-${day}-${year}`;
        },
        SetLayout(layout) {
            this.currentLayout = layout;
        },
        // UpLayout() {
        //     if (this.currentLayout < 32)
        //         this.SetLayout(this.currentLayout + 1);
        // },
        // DownLayout(layout) {
        //     if (this.currentLayout > 1)
        //         this.SetLayout(this.currentLayout - 1);

        // },
        CurrentStudy() {
            return this.studiesLoaded.filter(x => x.studyUID == this.currentStudy)[0];
        },
        GetImageIds(studyUID, seriesUID) {
            return this.cachedSeriesMetadata.filter(x => x.studyUID == studyUID && x.seriesUID == seriesUID)[0].imageIds
        },
        SelectCanvas(index) {
            this.selectedCanvas = index;
        },
        SelectSeries(study, series, element) {
            this.currentStudy = study;
            this.currentSeries = series.seriesUID;
            this.selectedSeriesElement = element;
            this.selectedSeries = series;

            const toolsStore = useToolsStore();
            const mainStore = useMainStore();
            const config = useConfigStore();
            const {
                ReferenceLinesTool,
                ToolGroupManager,
            } = cornerstoneTools;

            if (config.refLinesEnabled) {
                toolsStore.ChangeRefLines();
            }
        },
        MultiPart_parse(body, contentType) {
            // Examples for content types:
            //      multipart/form-data; boundary="----7dd322351017c"; ...
            //      multipart/form-data; boundary=----7dd322351017c; ...
            var m = contentType.match(/boundary=(?:"([^"]+)"|([^;]+))/i);
            var s;
            var fieldName = "jpeg";
            if (!m) {
                throw new Error('Bad content-type header, no multipart boundary');
            }

            var boundary = m[1] || m[2];

            function Header_parse(header) {
                var headerFields = {};
                var matchResult = header.match(/^.*name="([^"]*)"$/);
                if (matchResult) headerFields.name = matchResult[1];
                return headerFields;
            }

            function rawStringToBuffer(str) {
                var idx, len = str.length,
                    arr = new Array(len);
                for (idx = 0; idx < len; ++idx) {
                    arr[idx] = str.charCodeAt(idx) & 0xFF;
                }
                return new Uint8Array(arr).buffer;
            }

            // \r\n is part of the boundary.
            boundary = '\r\n--' + boundary;

            var isRaw = typeof(body) !== 'string';

            if (isRaw) {
                var view = new Uint8Array(body);
                s = String.fromCharCode.apply(null, view);
            } else {
                s = body;
            }

            // Prepend what has been stripped by the body parsing mechanism.
            s = '\r\n' + s;

            var parts = s.split(new RegExp(boundary)),
                partsByName = {};

            // First part is a preamble, last part is closing '--'
            for (var i = 1; i < parts.length - 1; i++) {
                var subparts = parts[i].split('\r\n\r\n');
                var headers = subparts[0].split('\r\n');
                for (var j = 1; j < headers.length; j++) {
                    var headerFields = Header_parse(headers[j]);
                    if (headerFields.name) {
                        fieldName = headerFields.name;
                    }
                }

                partsByName[fieldName] = isRaw ? rawStringToBuffer(subparts[1]) : subparts[1];
            }

            return partsByName;
        },
        hexToBase64(str) {
            // var bString = "";
            // for (var i = 0; i < str.length; i += 2) {
            //     bString += String.fromCharCode(parseInt(str.substr(i, 2), 16));
            // }
            // return btoa(unescape(encodeURIComponent(bString)));
            var hexArray = str
                .replace(/\r|\n/g, "")
                .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
                .replace(/ +$/, "")
                .split(" ");
            var byteString = String.fromCharCode.apply(null, hexArray);

            return window.btoa(byteString);
        },
        getPdfData(data) {
            var contentType = data.headers["content-type"].match(/boundary=(?:"([^"]+)"|([^;]+))/i);
            let contentTypeParts = contentType.split(';');
            let boundary = contentTypeParts[1].split('=')[1];
            let parts = data.data.message.split(boundary).filter(part => part !== '--' && part !== '');
            let parsedParts = parts[1].split('\n').filter(part => part !== '');
            let shifted = parsedParts.splice(4, parsedParts.length - 2);
            return shifted.join();
        },
        async fetchImage(request) {
            var myImage;

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error("Network response was not OK");
                }
                const myBlob = await response.blob();
                myImage.src = URL.createObjectURL(myBlob);
            } catch (error) {
                console.error("Error:", error);
            }
        },
        /**
         * WIP.......
         * @param {*} series 
         * @param {*} studyUID 
         */
        async instanceLevelQido(series, studyUID) {
            const utils = useUtilsStore();

            const config = useConfigStore();
            var instancesQido = `${config.qidoRoot}/studies/${studyUID}/series/${series.seriesUID}/instances?includefield=00080018&includefield=00080008&includefield=00200037&includefield=00200062&includefield=00080016`;
            var resp = await axios.get(instancesQido);

            for (let index = 0; index < resp.data.length; index++) {
                const instanceUID = resp.data[index]["00080018"] ? resp.data[index]["00080018"].value ? resp.data[index]["00080018"].Value[0] : "" : "";
                let imageType = resp.data[index]["00080008"] ? resp.data[index]["00080008"].Value ? resp.data[index]["00080008"].Value : [] : [];
                const imageOrientationPatient = resp.data[index]["00200037"] ? resp.data[index]["00200037"].Value ? resp.data[index]["00200037"].Value : [] : [];

                if (imageType && imageType.length > 0) {
                    imageType = imageType.map((item) => (item === null || item === undefined) ? '' : item).map(x => x.toUpperCase())
                }

                const imageLaterality = resp.data[index]["00200062"] ? resp.data[index]["00200062"].Value ? resp.data[index]["00200062"].Value[0] : "" : "";

                const instance = {
                    instanceUID,
                    imageType,
                    imageOrientationPatient,
                    imageLaterality
                }

                series.instanceData.push(instance);
            }

            const imageOrientations = series.instanceData.map(x => x.imageOrientationPatient).filter(x => x !== undefined && x !== "" && x !== null);
            if (imageOrientations && imageOrientations.length > 0) {

                const uniqueOrientations = utils.getListOfUniqueElements(imageOrientations);

                for (let i = 0; i < uniqueOrientations.length; i++) {
                    const imagePlane = utils.getImagePlaneFromVectorCosines(uniqueOrientations[i]);
                    series.imagePlanes.push(imagePlane);
                }
            }

            const imageTypes = series.instanceData.map(x => x.imageType).filter(x => x !== undefined && x !== "" && x !== null);
            if (imageTypes && imageTypes.length > 0) {

                const uniqueImageTypes = utils.getListOfUniqueElements(imageTypes);
                for (let i = 0; i < uniqueImageTypes.length; i++) {
                    series.imageTypes.push(uniqueImageTypes[i]);
                }
            }

            const imageLateralities = series.instanceData.map(x => x.imageLaterality).filter(x => x !== undefined && x !== "" && x !== null);
            if (imageLateralities && imageLateralities.length > 0) {

                const uniqueImageLateralities = utils.getListOfUniqueElements(imageLateralities);
                for (let i = 0; i < uniqueImageLateralities.length; i++) {
                    series.imageLateralities.push(uniqueImageLateralities[i]);
                }
            }
        },
        async seriesLevelQido(study) {
            //SERIES LEVEL QIDO for all series in study
            const config = useConfigStore();

            let self = this;

            var tempStudyUID = study.studyUID;
            var seriesQIDO = `${config.qidoRoot}/studies/${tempStudyUID}/series?includefield=00200060&includefield=00080021&includefield=00181030&includefield=00185101&includefield=00180015&includefield=00201209&includefield=0008103E&includefield=0020000E&includefield=00200011&includefield=00080060`;

            await axios.get(seriesQIDO).then(function(res) {
                var data = res.data;
                data.forEach(ser => {
                    var series = {
                        initialized: false,
                        studyDescription: study.studyDescription,
                        seriesNumber: self.GetAttribute(ser["00200011"]),
                        seriesUID: self.GetAttribute(ser["0020000E"]),
                        seriesRelatedInstances: self.GetAttribute(ser["00201209"]),
                        seriesDescription: self.GetAttribute(ser["0008103E"]),
                        seriesDate: self.GetAttribute(ser["00080021"]),
                        studyDate: study.studyDate,
                        modality: self.GetAttribute(ser["00080060"]),
                        bodyPartExamined: self.GetAttribute(ser["00180015"]),
                        viewPosition: self.GetAttribute(ser["00185101"]),
                        laterality: self.GetAttribute(ser["00200060"]),
                        protocolName: self.GetAttribute(ser["00181030"]),
                        imageIds: [],
                        instanceData: [],
                        imagePlanes: [],
                        imageTypes: [],
                        imageLateralities: [],
                    }

                    study.series.push(series);
                    if (!study.prior) {
                        self.mainSeriesUIDs.push(series.seriesUID);
                    } else {
                        self.priorSeriesUIDs.push(series.seriesUID);
                    }
                });
            })

            //sort series by series number
            study.series.sort((a, b) => a.seriesNumber - b.seriesNumber);

            return study;
        },
        async loadStudyFromServer(studyUID) {

            //tell the user what is happening
            var element = document.getElementById("viewer-placeholder-text");
            element.innerHTML = "Loading metadata";
            var dots = document.getElementById("dots");
            dots.style.display = "block";

            let self = this;
            const config = useConfigStore();

            //STUDY LEVEL QIDO
            var studyQido = `${config.qidoRoot}/studies?0020000D=${studyUID}&includefield=00101010&includefield=00100030&includefield=00081030&includefield=00100010&includefield=00100020&includefield=00080061&includefield=00080020&includefield=00080080&includefield=00080030`;
            await axios.get(studyQido).then(async function(res) {
                var data = res.data;
                var study = {
                    patientName: self.GetAttribute(data[0]["00100010"]),
                    patientID: self.GetAttribute(data[0]["00100020"]),
                    patientAge: self.GetAttribute(data[0]["00101010"]),
                    DOB: self.GetAttribute(data[0]["00100030"]),
                    studyUID: self.GetAttribute(data[0]["0020000D"]),
                    studyDescription: self.GetAttribute(data[0]["00081030"]),
                    studyDate: self.GetAttribute(data[0]["00080020"]),
                    studyTime: self.GetAttribute(data[0]["00080030"]),
                    modalitiesInStudy: self.GetAttribute(data[0]["00080061"]),
                    institutionName: self.GetAttribute(data[0]["00080080"]),
                    series: [],
                    bodyParts: [],
                    prior: false,
                }
                study = await self.seriesLevelQido(study);

                for (let i = 0; i < study.series.length; i++) {
                    await self.instanceLevelQido(study.series[i], study.studyUID);
                }


                let tempStudy = study;


                self.studiesLoaded.push(tempStudy);
            }).then(async function(e) {
                self.currentStudy = studyUID;
            }).then(async function(e) {
                self.SetLayout("2x2");

                //set default tools for STACK toolgroup
                const {
                    WindowLevelTool,
                    PanTool,
                    ZoomTool,
                    ToolGroupManager,
                    Enums: csToolsEnums,
                } = cornerstoneTools;
                const { MouseBindings } = csToolsEnums;


                //check with multiple modalities
                var lm = WindowLevelTool.toolName;
                var rm = ZoomTool.toolName;
                var mm = PanTool.toolName;

                var toolsStore = useToolsStore();
                toolsStore.activeMouseTools[0] = lm;
                toolsStore.activeMouseTools[1] = mm;
                toolsStore.activeMouseTools[2] = rm;


                var TG = cornerstoneTools.ToolGroupManager.getToolGroup("STACK_TOOLS");
                TG.setToolActive(lm, {
                    bindings: [{
                        mouseButton: MouseBindings.Primary,
                    }, ],
                });

                TG.setToolActive(mm, {
                    bindings: [{
                        mouseButton: MouseBindings.Auxiliary,
                    }, ],
                });

                TG.setToolActive(rm, {
                    bindings: [{
                        mouseButton: MouseBindings.Secondary,
                    }, ],
                });
                self.preDataLoaded = true;
            });
        },
        async setViewportLayoutForStudy() {

            var study = this.studiesLoaded.filter(x => x.studyUID == self.currentStudy);
            let seriesModalities = study[0].series.map(x => x.modality)

            let modality;

            /**
             * cycle through ignoring "DOC" modality until genuine modality found 
             * if there is only DOC modality then use DOC layout
             */
            for (let i = 0; i < seriesModalities.length; i++) {
                modality = seriesModalities[i];
                let bool = (!(modality === "DOC") && !(modality === "SR") && !(modality === "DUMMY"))
                if (bool) {
                    self.studiesLoaded.modalitiesInStudy = modality;

                    // break from loop 
                    i = seriesModalities.length;
                }
            }

            //var layoutCookie = UIstore.getCookie(modalitiesInStudy);
            var layoutForModality = self.defaults.filter(x => x.modality == modality);
            var layout;

            // new modality detected, add to db 
            if (layoutForModality.length == 0) {
                let query = `${config.defaultsRoot}/GetDefaults?modality=${modality}`;
                layoutForModality[0] = await self.GetAllDefaults(query);
                self.defaults.push(layoutForModality[0].data);
                layout = `${layoutForModality[0].data.row}x${layoutForModality[0].data.col}`;
            } else {
                layout = `${layoutForModality[0].row}x${layoutForModality[0].col}`;
            }

            if (layout) {
                self.SetLayout(layout);
            }
        },
        async getAndSetAllDefaults() {
            const UIstore = useUIStore();
            const config = useConfigStore();

            var query = `${config.defaultsRoot}/GetAllDefaults`;
            var res = await this.GetAllDefaults(query);
            this.defaults = res.data;

            query = `${config.defaultsRoot}/GetThumbnailDefaults`;
            res = await this.GetThumbnailDefaults(query);
            this.thumbnailDefaults = res.data[0];

            if (this.thumbnailDefaults['thumbnailHSize']) {
                UIstore.thumbnailHSize = this.thumbnailDefaults['thumbnailHSize'];
            }

            if (this.thumbnailDefaults['thumbnailVSize']) {
                UIstore.thumbnailVSize = this.thumbnailDefaults['thumbnailVSize'];
            }

            if (this.thumbnailDefaults['thumbnailHbottom'] !== "") {
                UIstore.thumbnailBottom = this.thumbnailDefaults['thumbnailHbottom'] == 1;
            }

            if (this.thumbnailDefaults['thumbnailVleft'] !== "") {
                UIstore.thumbnailLeft = this.thumbnailDefaults['thumbnailVleft'] == 1;
            }
        },
        GetAllDefaults(query) {
            return axios.get(query)
        },
        GetThumbnailDefaults(query) {
            return axios.get(query)
        },
        ProcessSR(data) {
            var resultHTML = "";
            var TopLevelattributesHTML = "";

            //List of top level attributes we are interested in
            var topLevelAttr = [
                { "Name": "00100010" },
                { "ID": "00100020" },
                { "Date of Birth": "00100030" },
                { "Sex": "00100040" },
                { "Completion": "0040A491" },
                { "Verification": "0040A493" }
            ];

            //Add the attributes to the html
            topLevelAttr.forEach(attr => {
                var tagName = Object.keys(attr)[0];
                var attrTag = Object.values(attr)[0]
                var attrValue = this.GetAttribute(data[attrTag]);


                //specific attributes that need extra conversion
                if (tagName == "Date of Birth") {
                    var year = attrValue.substring(0, 4);
                    var month = attrValue.substring(4, 6);
                    var day = attrValue.substring(6, 8);
                    attrValue = `${month}-${day}-${year}`;
                } else if (tagName == "Sex") {
                    if (attrValue == "M")
                        attrValue = "Male"
                    if (attrValue == "F")
                        attrValue = "Female"
                    if (attrValue == "O")
                        attrValue = "Other"
                    if (attrValue == "U")
                        attrValue = "Unknown"
                }

                var lineHTML = `<div class='sr-attribute'>${tagName} : ${attrValue}</div>`;
                TopLevelattributesHTML += lineHTML
            });

            //parse content sequence
            var ContentSequence = { "Content Sequence": "0040A730" };
            var contentSQname = Object.keys(ContentSequence)[0];
            var contentSQtag = Object.values(ContentSequence)[0]
            var contentSQvalue = data[contentSQtag];


            //added warning until sr parse is done properly
            var warningHTML = `<div class='sr-warning'>!! SR is not fully supported in this verion !!</div>`;

            //Assemble the SR html 
            resultHTML += `<div class='sr-data'> ` +
                `<div class='sr-data-title'> Structured Report (SR)</div><hr style="width: 50%; border: 1px solid #9b7a3c;"/>` +
                `<br/>` +
                `${TopLevelattributesHTML}` +
                `<br/><hr/>` +
                `${warningHTML}` +
                `</div>`;
            return resultHTML;
        },
        GetAttribute(attribute) {
            if (attribute && attribute.Value) {
                if (attribute.Value[0].Alphabetic)
                    return attribute.Value[0].Alphabetic;
                else
                    return attribute.Value[0];
            } else
                return "";
        },
        handleMultiStackScroll(event) {
            let visibleMultiSelected = document.getElementsByClassName("multi-selected");
            if (visibleMultiSelected.length == 0) {
                return;
            }
            //-1 = up : 1 = down 
            const scrollDirection = event.deltaY < 0 ? -1 : 1;
            ////Update image indexes for multi selected viewports
            this.multiSelectedViewports.forEach((viewport) => {
                    viewport.scroll(scrollDirection);
                })
                // this.multiSelectedViewports.map((viewport) => {
                //     viewport.scroll(scrollDirection);
                // })
        },
        removeMultiSelected(viewport) {
            const index = this.multiSelectedViewports.indexOf(viewport);
            if (index !== -1) {
                this.multiSelectedViewports.splice(index, 1);
            }

            // const cacheIndex = this.multiSelectedViewportCache.indexOf(viewport);

            // if(cacheIndex !== -1) {
            //     this.multiSelectedViewportCache.splice(cacheIndex, 1);
            // }
        }
    }
});