import { api } from 'dicomweb-client';
import dcmjs from 'dcmjs';
import { utilities } from '@cornerstonejs/core';
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";

import WADORSHeaderProvider from './WADORSHeaderProvider';
import getPixelSpacingInformation from './getPixelSpacingInformation';

const { DicomMetaDictionary } = dcmjs.data;
const { calibratedPixelSpacingMetadataProvider } = utilities;

const VOLUME = 'volume';

/**
 * Uses dicomweb-client to fetch metadata of a study, cache it in cornerstone,
 * and return a list of imageIds for the frames.
 *
 * Uses the app config to choose which study to fetch, and which
 * dicom-web server to fetch it from.
 *
 * @returns {string[]} An array of imageIds for instances in the study.
 */

export default async function createImageIdsAndCacheMetaDataFirst({
    StudyInstanceUID,
    SeriesInstanceUID,
    wadoRsRoot,
    type,
}) {
    const SOP_INSTANCE_UID = '00080018';
    const SERIES_INSTANCE_UID = '0020000E';
    const MODALITY = '00080060';

    const studySearchOptions = {
        studyInstanceUID: StudyInstanceUID,
        seriesInstanceUID: SeriesInstanceUID,
        limit: "1",
    };

    const client = new api.DICOMwebClient({ url: wadoRsRoot });

    const instances = await client.retrieveSeriesMetadata(studySearchOptions);
    const modality = instances[0][MODALITY].Value[0];

    const imageIds = instances.map((instanceMetaData) => {
        const SeriesInstanceUID = instanceMetaData[SERIES_INSTANCE_UID].Value[0];
        const SOPInstanceUID = instanceMetaData[SOP_INSTANCE_UID].Value[0];

        const prefix =
            type.toLowerCase() === VOLUME ? 'streaming-wadors:' : 'wadors:';

        const imageId =
            prefix +
            wadoRsRoot +
            '/studies/' +
            StudyInstanceUID +
            '/series/' +
            SeriesInstanceUID +
            '/instances/' +
            SOPInstanceUID +
            '/frames/1';

        cornerstoneWADOImageLoader.wadors.metaDataManager.add(
            imageId,
            instanceMetaData
        );

        WADORSHeaderProvider.addInstance(imageId, instanceMetaData);

        // Add calibrated pixel spacing
        const m = JSON.parse(JSON.stringify(instanceMetaData));
        const instance = DicomMetaDictionary.naturalizeDataset(m);
        var pixelSpacing = getPixelSpacingInformation(instance);

        //hotfix for no pixel spacing data and for the other scenario where pixelspacing.pixelspacing
        if (!pixelSpacing)
            pixelSpacing = [1, 1];

        try {
            pixelSpacing.map((s) => parseFloat(s))
        } catch {
            pixelSpacing = pixelSpacing.PixelSpacing;
        }

        calibratedPixelSpacingMetadataProvider.add(
            imageId,
            pixelSpacing.map((s) => parseFloat(s))
        );

        return imageId;
    });

    return imageIds;
}