import { Auth0VueClient } from "@auth0/auth0-vue";
import { ref } from "vue";

export const client = ref();

export function exposeAuth0() {
  return {
    install(app) {
      client.value = app.config.globalProperties["$auth0"];
    },
  };
}